import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import SEO from '../components/shared/seo'
import Layout from '../components/shared/layout'
import { isBrowser, isAuthenticated, login } from '../utils/auth.service'

export default () => {

  if (isBrowser && isAuthenticated()) {
    navigate('/');
    return null;
  }

  if (isBrowser) {
    login()
  }

  return (
    <Layout>

      <SEO title='Login' />
      <MsgStyle>Redirecting you to the login screen ...</MsgStyle>
    </Layout>
  );
};

const MsgStyle = styled.div`${tw`text-center text-3xl p-32 text-gray-700`}`
